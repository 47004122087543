import React from "react";
import "./ContactUs.css";
import { Padding } from "@mui/icons-material";

const ContactUsPage = () => {
  return (
    <div className="main-cont">
      <h2 className="Conatact-page-main" style={{ color: "whitesmoke" }}>
        Contact Us
      </h2>
      <div className="Main-conatactus-details">
        <div className="Principal-details">
          <h4 className="Princiapl_head">Principal </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. S, Gopi Krishna
          </p>
          <p>
            <b>Contact No:</b> 9000453733 / 9032727017
          </p>
          <p>
            <b>Email ID:</b> smce.principal@gmail.com
          </p>
        </div>
        <div className="Principal-details">
          <h4 className="Princiapl_head">HoD CSE </h4>
          <p className="principal_name">
            <b>Name:</b>Mr. V. Kesav Kumar
          </p>
          <p>
            <b>Contact No:</b> 8187033818
          </p>
          <p>
            <b>Email ID:</b> smce.hodcse@gmail.com
          </p>
        </div>
        <div className="Principal-details">
          <h4 className="Princiapl_head">HoD ECE </h4>
          <p className="principal_name">
            <b>Name:</b>Mr. P. Mahaboob Subhani
          </p>
          <p>
            <b>Contact No:</b> : 9885004373
          </p>
          <p>
            <b>Email ID:</b> smce.hodece@gmail.com
          </p>
        </div>
      </div>
      <div className="Main-conatactus-details">
        <div className="Principal-details">
          <h4 className="Princiapl_head">HoD IT/AI/DS </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. Md. Rafi
          </p>
          <p>
            <b>Contact No:</b> 9346027169
          </p>
          <p>
            <b>Email ID:</b> smce.hodit@gmail.com
          </p>
        </div>
        <div className="Principal-details">
          <h4 className="Princiapl_head">HoD S&H(Part – A) </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. M.Venkata Pavan kumar
          </p>
          <p>
            <b>Contact No:</b> 9966077732
          </p>
          <p>
            <b>Email ID:</b> smce.hodash@gmail.com
          </p>
        </div>
        <div className="Principal-details">
          <h4 className="Princiapl_head">HoD S&H (Part – B:) </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. V. Madhuri
          </p>
          <p>
            <b>Contact No:</b> 9849569101
          </p>
          <p>
            <b>Email ID:</b> smce.hodash@gmail.com
          </p>
        </div>
      </div>
      <div className="Main-conatactus-details">
        <div className="Principal-details">
          <h4 className="Princiapl_head">HoD MBA </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. Sk. J. Shareef
          </p>
          <p>
            <b>Contact No:</b> 9441909216
          </p>
          <p>
            <b>Email ID:</b> smce.hodmba@gmail.com
          </p>
        </div>

        <div className="Principal-details">
          <h4 className="Princiapl_head">IQAC </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. Sk. J. Shareef
          </p>
          <p>
            <b>Contact No:</b> 9441909216
          </p>
          <p>
            <b>Email ID:</b> smce.iqac@gmail.com
          </p>
        </div>
        <div className="Principal-details">
          <h4 className="Princiapl_head">Controller of Examinations </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. M. Nageswara Rao
          </p>
          <p>
            <b>Contact No:</b> 8328549357
          </p>
          <p>
            <b>Email ID:</b> smce.examsincharge@gmail.com
          </p>
        </div>
      </div>
      <div className="Main-conatactus-details">
        <div className="Main_Training_placement_cell">
          <h4 className="Princiapl_head">Training &Placement Cell</h4>
          <div style={{display:"flex"}}>
            <div style={{paddingLeft:"5px"}}>
              <p>
                <b>Name:</b>Mr.Syam Kumar Reddy
              </p>
              <p><b>Designation:</b>Head Placements</p>
              <p>
                <b>Contact No:</b>9885553622
              </p>
            </div>
            <div style={{paddingLeft:"12.2px"}}>
            <p>
                <b>Name:</b>Dr. M. Nageswara Rao
              </p>
              <p><b>Designation:</b>Training & Placement office</p>
              <p>
                <b>Contact No:</b>8328549357  
              </p>
            </div>
          </div>
          <p style={{textAlign:"center"}}>
            <b>Email ID:</b> tpcellsmce@gmail.com
          </p>
        </div>

        <div className="Principal-details">
          <h4 className="Princiapl_head">Administrative Officer </h4>
          <p className="principal_name">
            <b>Name:</b>Mr. Ch. Srinivasa Rao
          </p>
          <p>
            <b>Contact No:</b> : 9000447117
          </p>
          <p>
            <b>Email ID:</b> smce.ao@gmail.com
          </p>
        </div>

        <div className="Principal-details">
          <h4 className="Princiapl_head">R & D Cell </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. B. Nageswara Rao Naik
          </p>
          <p>
            <b>Contact No:</b> 9963077408
          </p>
          <p>
            <b>Email ID:</b> smce.rand2@gmail.com
          </p>
        </div>
      </div>
      {/* <div className="Main-conatactus-details" style={{alignItems:"center"}}>
        <div className="Principal-details">
          <h4 className="Princiapl_head">R & D Cell </h4>
          <p className="principal_name">
            <b>Name:</b>Dr. B. Nageswara Rao Naik
          </p>
          <p>
            <b>Contact No:</b> 9963077408
          </p>
          <p>
            <b>Email ID:</b> smce.rand2@gmail.com
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default ContactUsPage;
